import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@components/Button/Button';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class TextPhoto extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    if (this.props.topDown) {
      return (
        <section className={`text-photo top-down ${this.props.columnsClasses}`}>
          <div className="columns">
            <div className={`column is-${this.props.topSideColumnIs}`}></div>
            {this.props.topColumn}
            <div className={`column is-${this.props.topSideColumnIs}`}></div>
          </div>
          <div className="columns">
            <div className={`column is-${this.props.bottomSideColumnIs}`}></div>
            {this.props.bottomColumn}
            <div className={`column is-${this.props.bottomSideColumnIs}`}></div>
          </div>
        </section>
      )
    }
    return (
        <div className={`columns text-photo ${this.props.columnsClasses}`}>
            <div className={`column is-${this.props.sideColumnIs}`}></div>
            {this.props.leftColumn}
            <div className={`column is-${this.props.centerColumnIs}`}>
            </div>
            {this.props.rightColumn}
            <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
    )
  }
}

TextPhoto.propTypes = {
  leftColumn: PropTypes.element,
  rightColumn: PropTypes.element,
  centerColumnIs: PropTypes.number,
  sideColumnIs: PropTypes.number,
  columnsClasses: PropTypes.string
}

export default TextPhoto
